import React, { useState, useEffect } from "react";
import styles from "./Pagar.module.css";
import Footer from "../elements/Footer";
import Moment from "react-moment";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import windowSize from "../elements/WindowsWatch";
import validator from "validator";
import copyImg from "../images/copiar-payment.png";
import pixIconImg from "../images/pix-icon.png";
import pixIconCheckedImg from "../images/pix-icon-checked.png";
import cardIconImg from "../images/cartao-icon.png";
import cardIconCheckedImg from "../images/cartao-icon-checked.png";
import boletoIconImg from "../images/boleto-icon.png";
import boletoIconCheckedImg from "../images/boleto-icon-checked.png";
import paymentDeniedImg from "../images/payment-denied.png";
import Loader from "../elements/Loader";
import FailMsg from "../elements/FailMsg";
import QRCodeGen from "../elements/QRCodeGen";
import pagarlogo from "../images/pagar-plano-01.png";
import getGlobalVariable from "../elements/MainVariables";
import pagarme from "pagarme";

function Pagar() {
  let installments = [];


  const [msgFail, setMsgFail] = useState();

  const { width } = windowSize();

  const [instalList, setInstalList] = useState();
  const [displayFail, setDisplayFail] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState();
  const [loader, setLoader] = useState();

  const { register, setValue, setFocus } = useForm();
  const { height } = windowSize();


  const [page, setPage] = useState(1);
  const [progressBar, setProgressBar] = useState(0);
  const [paymentDenied, setPaymentDenied] = useState(false);
  const [paymentType, setPaymentType] = useState(0);
  const [validCep, setValidCep] = useState(0);

  const [scrollBarText, setScrollBarText] = useState({
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
  });

  const plano = {
    title: "AG Premium",
    subtitle: "Plano mensal",
    img: pagarlogo,
    paymentPix: true,
    paymentCreditCard: true,
    paymentBoleto: true,
    credit_card_installments: 6,
    value: 1000,
    beneficios: [
      "Controle de Fluxo de Caixa",
      "Lançamentos de contas a pagar",
      "Lançamentos de contas a receber",
      "Categorização de recebimentos e pagamentos",
      "Conciliação de conta bancária",
      "Suporte Premium",
    ],
  }
  /*
  const planoGratis = {
    title: "AG Premium",
    subtitle: "Plano mensal",
    img: pagarlogo,
    paymentPix: true,
    paymentCreditCard: true,
    paymentBoleto: true,
    credit_card_installments: 6,
    value: 1000,
    beneficios: [
      "Controle de Fluxo de Caixa",
      "Lançamentos de contas a pagar",
      "Lançamentos de contas a receber",
      "Categorização de recebimentos e pagamentos",
      "Conciliação de conta bancária",
      "Suporte Premium",
    ],
  }
  */
  

  const [userRegister, setUserRegister] = useState({
    nome: "",
    phone: "",
    cpf: "",
    email: "",
    cidade: "",
    cep: "",
    estado: "",
    numeroEndereco: "",
    bairro: "",
    endereco: "",
    complemento: "",
    cardNumber: "",
    cardDate: "",
    cardName: "",
    cardCvv: "",
  });

  const formFail = (msg) => {
    setMsgFail(msg);
    setDisplayFail(true);
    setTimeout(() => {
      setDisplayFail(false);
    }, "1500");
  };

  /*
  const getData = () => {
    fetch(`${preUuidUrl}${uuid}`)
      .then((response) => response.json())
      .then((data) => setPayment(data))
      .catch((error) => checkError(error));
  };
    */


  const setPaymentMethod = () => {
    if (paymentType === 1) return "pix";
    else if (paymentType === 2) return "credit_card";
    else if (paymentType === 3) return "boleto";
  };

  const postData = (paymentType, cardHash = undefined) => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let raw;

    if (userRegister.complemento === undefined) {
      userRegister.complemento = "";
    }

    if (paymentType === 2) {
      raw = JSON.stringify({
        customer: {
          name: userRegister.nome,
          phone: String(userRegister.phone).replace(/\D/g, ""),
          email: userRegister.email,
          cpf: String(userRegister.cpf).replace(/\D/g, ""),
          address: {
            zipcode: String(userRegister.cep).replace(/\D/g, ""),
            street: userRegister.endereco,
            street_number: userRegister.numeroEndereco,
            neighborhood: userRegister.bairro,
            complementary: userRegister.complemento,
            city: userRegister.cidade,
            state: userRegister.estado.toLowerCase(),
            country: "br",
          },
        },
        payment_method: setPaymentMethod(),
        credit_card_hash: cardHash,
        installments: parseInt(userRegister.installments),
      });
    } else {
      raw = JSON.stringify({
        customer: {
          name: userRegister.nome,
          phone: String(userRegister.phone).replace(/\D/g, ""),
          email: userRegister.email,
          cpf: String(userRegister.cpf).replace(/\D/g, ""),
          address: {
            zipcode: String(userRegister.cep).replace(/\D/g, ""),
            street: userRegister.endereco,
            street_number: userRegister.numeroEndereco,
            neighborhood: userRegister.bairro,
            complementary: userRegister.complemento,
            city: userRegister.cidade,
            state: userRegister.estado.toLowerCase(),
            country: "br",
          },
        },
        payment_method: setPaymentMethod(),
      });
    }
    console.log(raw);
    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    if (paymentType === 2) {
      
      fetch(`https://xwhfvujhvvctrgfgxwsz.supabase.co/functions/v1/register-team`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "paid") {
            setPaymentResponse(response);
            setLoader(false);
            setPage(page + 1);
            setProgressBar(progressBar + 25);
            setScrollBarText((prevValue) => ({
              ...prevValue,
              four: "✓",
              five: "✓",
            }));
          } else {
            setPaymentDenied(1);
            setLoader(false);
          }
        })

    } else {
      fetch(`https://xwhfvujhvvctrgfgxwsz.supabase.co/functions/v1/register-team`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.error === undefined) {
            setPaymentResponse(response);
            setPage(page + 1);
            setProgressBar(progressBar + 25);
            setLoader(false);
            setScrollBarText((prevValue) => ({
              ...prevValue,
              four: "✓",
              five: "✓",
            }));
          } else {
            setPaymentDenied(2);

            setLoader(false);
          }
        })
      
    }
  };

  const getCep = (e, pageValue = "") => {
    let cep;
    if (pageValue === "") {
      cep = String(e.target.value).replace(/\D/g, "");
    } else {
      cep = String(pageValue).replace(/\D/g, "");
    }

    if (cep !== undefined) {
      if (String(cep).replace(/\D/g, "").length === 8) {
        fetch(`${getGlobalVariable().mainUrl}/util/cep/${cep}`)
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              setValidCep(2);
            } else {
              setValidCep(3);
              setUserRegister((prevValue) => ({
                ...prevValue,
                cep: data.zipcode,
                endereco: data.street,
                bairro: data.neighborhood,
                cidade: data.city,
                estado: data.state,
              }));
              setValue("cep", String(cep).replace(/\D/g, ""));
              setValue("endereco", data.street);
              setValue("bairro", data.neighborhood);
              setValue("cidade", data.city);
              setValue("estado", data.state.toUpperCase());
              if (pageValue === "") {
                setFocus("numeroEndereco");
              }
            }
          });
      } else {
        setValidCep(1);
      }
    }
  };

  const handleChangeValues = (value) => {
    setUserRegister((prevValue) => ({
      ...prevValue,
      [value.target.name]: value.target.value,
    }));

    if (
      plano.credit_card_installments === 1 &&
      userRegister.installments !== 1
    ) {
      setUserRegister((prevValue) => ({
        ...prevValue,
        installments: 1,
      }));
    }
  };

  const isValidCPF = (cpf) => {
    cpf = cpf.replace(/[\s.-]*/gim, "");
    if (
      !cpf ||
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    ) {
      return false;
    }

    var sum = 0;
    var resto;
    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (sum * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(9, 10))) {
      return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (sum * 10) % 11;
    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(cpf.substring(10, 11))) {
      return false;
    }
    return true;
  };

  const nextPage = () => {
    const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (page === 1) {
      if (userRegister.nome === "") {
        formFail("Preencha todos os campos!");
      } else if (!userRegister.email.match(isValidEmail)) {
        formFail("Email Inválido!");
      } else if (
        !validator.isMobilePhone(userRegister.phone, ["pt-BR", [true]])
      ) {
        formFail("Telefone Inválido!");
      } else if (!isValidCPF(String(userRegister.cpf).replace(/\D/g, ""))) {
        formFail("CPF Inválido!");
      } else {
        setPage(page + 1);
        setProgressBar(progressBar + 25);
        setScrollBarText((prevValue) => ({
          ...prevValue,
          two: "✓",
        }));
      }
    } else if (page === 2) {
      if (
        userRegister.endereco !== "" &&
        userRegister.cep !== "" &&
        String(userRegister.cep).replace(/\D/g, "").length === 8 &&
        userRegister.cidade !== "" &&
        userRegister.estado !== "" &&
        userRegister.estado !== "default" &&
        userRegister.numeroEndereco !== "" &&
        userRegister.bairro !== ""
      ) {
        setPage(page + 1);
        setValidCep(3);
        setProgressBar(progressBar + 25);
        setScrollBarText((prevValue) => ({
          ...prevValue,
          three: "✓",
        }));
      } else {
        formFail("Preencha os campos obrigatórios!");
      }
    } else if (page === 3) {
      // setar tipo de pagamento
      // 1 - pix
      // 2 - cartão
      // 3 - boleto

      if (paymentType === 1) {
        setLoader(true);
        setUserRegister((prevState) => ({
          ...prevState,
          paymentType: "pix",
        }));

        postData(paymentType);
      } else if (paymentType === 2) {
        if (
          userRegister.cardDate !== "" &&
          userRegister.cardName !== "" &&
          userRegister.cardCvv !== "" &&
          userRegister.cardNumber !== "" &&
          userRegister.installments !== undefined &&
          userRegister.installments !== "default"
        ) {
          if (userRegister.cardDate.replace("/", "")) {
            let creditDate = new Date(
              `01/${userRegister.cardDate.split("/")[0]}/20${
                userRegister.cardDate.split("/")[1]
              }`
            );
            const atualDate = new Date();
            if (creditDate < atualDate) {
              formFail("Data Inválida");
            } else {
              setLoader(true);
              setUserRegister((prevState) => ({
                ...prevState,
                paymentType: "credit_card",
              }));

              pagarme.client
                .connect({ encryption_key: getGlobalVariable().encryptKey })
                .then((client) => {
                  return client.security.encrypt({
                    card_number: userRegister.cardNumber.replace(/ /g, ""),
                    card_holder_name: userRegister.cardName,
                    card_expiration_date: userRegister.cardDate.replace(
                      "/",
                      ""
                    ),
                    card_cvv: userRegister.cardCvv.replace(/ /g, ""),
                  });
                })
                .then((card_hash) => postData(paymentType, card_hash));
            }
          } else {
            formFail("Preencha todos os campos!");
          }
        } else {
          formFail("Preencha todos os campos!");
        }
      } else if (paymentType === 3) {
        setLoader(true);
        setUserRegister((prevState) => ({
          ...prevState,
          paymentType: "boleto",
        }));

        postData(paymentType);
      } else if (paymentType === 0) {
        formFail("Selecione o método de pagamento");
      }
    } else if (page === 5) {
      setScrollBarText.four("✓");
    } else {
      setProgressBar(progressBar + 25);
      setPage(page + 1);
      setScrollBarText((prevValue) => ({
        ...prevValue,
        one: "✓",
      }));
    }
  };

  const prevPage = () => {
    if (page === 2) {
      setScrollBarText((prevValue) => ({
        ...prevValue,
        one: "1",
      }));
    } else if (page === 3) {
      setScrollBarText((prevValue) => ({
        ...prevValue,
        two: "2",
      }));
      setValue("nome", userRegister.nome);
      setValue("email", userRegister.email);
      setValue("phone", userRegister.phone);
      setValue("cpf", userRegister.cpf);
    } else if (page === 4) {
      setScrollBarText((prevValue) => ({
        ...prevValue,
        three: "3",
      }));

      getCep("", userRegister.cep);
      setValue("complemento", userRegister.complemento);
      setValue("numeroEndereco", userRegister.numeroEndereco);
    }
    setProgressBar(progressBar - 25);
    setPage(page - 1);
  };

  const copyText = (type) => {
    if (type === 1) {
      navigator.clipboard.writeText(paymentResponse.pix);
    } else if (type === 3) {
      navigator.clipboard.writeText(paymentResponse.boleto_code);
    }
  };

  const keyPressHandler = (e) => {
    if (e.which === 13) {
      nextPage();
    }
  };

  const numberFormat = (value) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);

  const creditCardPayment = (e) => {
    e.preventDefault();
    setPaymentType(2);
    if (
      plano.credit_card_installments !== null &&
      installments !== plano.credit_card_installments
    ) {
      for (let i = 1; i <= plano.credit_card_installments; i++) {
        installments.push({
          installment: i,
          finalValue: Math.ceil(plano.value / i),
        });
      }
    }
    setInstalList(installments);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {loader && <Loader />}
      {displayFail && <FailMsg msg={msgFail} />}

      <div>
        <div className={styles.loadingBarArea}>
          <div className={styles.loadingBarContainer}>
            <div className={styles.loadingLineTwo}>
              <div
                className={styles.greenLine}
                style={{ width: `${progressBar}%` }}
              ></div>
            </div>
          </div>
          <div className={styles.loadingCirclesContainerTwo}>
            <div className={styles.loadingCircleTwo}>
              <div className={styles.greenCircle} style={{ width: "1.5rem" }}>
                <label>{scrollBarText.one}</label>
              </div>
            </div>
            <div className={styles.loadingCircleTwo}>
              {progressBar > 19 && (
                <div className={styles.greenCircle}>
                  <label>{scrollBarText.two}</label>
                </div>
              )}
            </div>
            <div className={styles.loadingCircleTwo}>
              {progressBar > 39 && (
                <div className={styles.greenCircle}>
                  <label>{scrollBarText.three}</label>
                </div>
              )}
            </div>
            <div className={styles.loadingCircleTwo}>
              {progressBar > 59 && (
                <div className={styles.greenCircle}>
                  <label>{scrollBarText.four}</label>
                </div>
              )}
            </div>
          </div>
          <div className={styles.loadingCirclesDescript}>
            <h4>Dados pessoais</h4>
            <h4>Endereço de cobrança</h4>
            <h4>Método de pagamento</h4>
            <h4>Confirmação</h4>
          </div>
        </div>

        {page === 1 && (
          <div className={styles.descrptContainer}>
            <div className={styles.descrptSubContainer}>
              <h4 className={styles.pageTwoTitle}>Dados pessoais</h4>
              <div className={styles.descrptArea}>
                <input
                  className={styles.textInput}
                  {...register("nome")}
                  onKeyDown={keyPressHandler}
                  onChange={handleChangeValues}
                  name="nome"
                  placeholder="Nome completo*"
                ></input>
                <input
                  className={styles.textInput}
                  {...register("email")}
                  onKeyDown={keyPressHandler}
                  onChange={handleChangeValues}
                  name="email"
                  placeholder="E-mail*"
                ></input>
                <InputMask
                  className={styles.textInput}
                  {...register("phone")}
                  onKeyDown={keyPressHandler}
                  onChange={handleChangeValues}
                  value={userRegister.phone}
                  mask="(99) 99999-9999"
                  maskChar=""
                  name="phone"
                  placeholder="Telefone*"
                />
                <InputMask
                  className={styles.textInput}
                  {...register("cpf")}
                  onKeyDown={keyPressHandler}
                  onChange={handleChangeValues}
                  value={userRegister.cpf}
                  mask="999.999.999-99"
                  maskChar=""
                  alwaysShowMask="false"
                  name="cpf"
                  placeholder="CPF*"
                />
              </div>
            </div>
            {width > 420 && (
              <div className={styles.payInfoContainer}>
                <div className={styles.payInfoSubContainer}>
                  <h4 className={styles.pageTwoTitle}>Resumo</h4>
                  <div className={styles.paymentInfo}>
                    <img src={plano.img} alt="time icone"></img>
                    <h3>{plano.title}</h3>
                    <h4>{plano.subtitle}</h4>
                    {plano.beneficios.map((text) => {
                      return (
                        <div className={styles.cardplan}>
                          <img
                            src="images/check-icon.png"
                            alt="ícone de checado"
                          ></img>
                          <h5>{text}</h5>
                        </div>
                      );
                    })}
                    <div className={styles.lineBreak}></div>

                    <div className={styles.paymentInfoItem}>
                      <h4>Valor total</h4>

                      <h5>
                        R${plano.value.toString().slice(0, -2)},
                        {plano.value.toString().slice(-2)} / mês
                      </h5>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className={styles.nextPageContainer}>
                  <div></div>

                  <button onClick={nextPage} className={styles.nextBtn}>
                    Próximo passo
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {page === 2 && (
          <div className={styles.descrptContainer}>
            <div className={styles.descrptSubContainer}>
              <h4 className={styles.pageTwoTitle}>Endereço de cobrança</h4>
              <div className={styles.descrptArea}>
                <InputMask
                  className={styles.textInput}
                  {...register("cep")}
                  onBlur={getCep}
                  onKeyDown={keyPressHandler}
                  value={userRegister.cep}
                  onChange={handleChangeValues}
                  mask="99999-999"
                  maskChar=""
                  alwaysShowMask="false"
                  name="cep"
                  placeholder="CEP*"
                />
                {validCep === 1 && <h3>CEP Inválido!</h3>}
                {validCep === 2 && (
                  <h3>CEP não encontrado, por favor confira novamente</h3>
                )}
                <input
                  disabled={validCep === 3 ? true : false}
                  className={styles.textInput}
                  onKeyDown={keyPressHandler}
                  {...register("endereco")}
                  onChange={handleChangeValues}
                  name="endereco"
                  placeholder="Endereço*"
                />
                <div className={styles.twinInput}>
                  <input
                    className={styles.textInput}
                    {...register("numeroEndereco")}
                    onKeyDown={keyPressHandler}
                    maxLength="10"
                    onChange={handleChangeValues}
                    name="numeroEndereco"
                    placeholder="Número*"
                  />
                  <input
                    className={styles.textInput}
                    {...register("complemento")}
                    onKeyDown={keyPressHandler}
                    onChange={handleChangeValues}
                    name="complemento"
                    placeholder="Complemento"
                  />
                </div>
                <input
                  disabled={validCep === 3 ? true : false}
                  className={styles.textInput}
                  {...register("bairro")}
                  onKeyDown={keyPressHandler}
                  onChange={handleChangeValues}
                  name="bairro"
                  placeholder="Bairro*"
                ></input>
                <div className={styles.twinInput}>
                  <input
                    disabled={validCep === 3 ? true : false}
                    className={styles.textInput}
                    {...register("cidade")}
                    onKeyDown={keyPressHandler}
                    onChange={handleChangeValues}
                    name="cidade"
                    placeholder="Cidade*"
                  />
                  <select
                    disabled={validCep === 3 ? true : false}
                    className={styles.selectTextInput}
                    {...register("estado")}
                    onChange={handleChangeValues}
                    name="estado"
                    placeholder=""
                  >
                    <option value="default">Selecione o Estado*</option>
                    <option>AL</option>
                    <option>AP</option>
                    <option>AM</option>
                    <option>BA</option>
                    <option>CE</option>
                    <option>DF</option>
                    <option>ES</option>
                    <option>GO </option>
                    <option>MA</option>
                    <option>MT</option>
                    <option>MS</option>
                    <option>MG</option>
                    <option>PA</option>
                    <option>PB</option>
                    <option>PE</option>
                    <option>PI</option>
                    <option>RJ</option>
                    <option>RN</option>
                    <option>RS</option>
                    <option>RO</option>
                    <option>RR</option>
                    <option>SC</option>
                    <option>SP</option>
                    <option>SE</option>
                    <option>TO</option>
                  </select>
                </div>
              </div>
            </div>
            {width > 420 && (
              <div className={styles.payInfoContainer}>
                <div className={styles.payInfoSubContainer}>
                  <h4 className={styles.pageTwoTitle}>Resumo</h4>
                  <div className={styles.paymentInfo}>
                    <img src={plano.img} alt="time icone"></img>
                    <h3>{plano.title}</h3>
                    <h4>{plano.subtitle}</h4>
                    {plano.beneficios.map((text) => {
                      return (
                        <div className={styles.cardplan}>
                          <img
                            src="images/check-icon.png"
                            alt="ícone de checado"
                          ></img>
                          <h5>{text}</h5>
                        </div>
                      );
                    })}
                    <div className={styles.lineBreak}></div>

                    <div className={styles.paymentInfoItem}>
                      <h4>Valor total</h4>

                      <h5>
                        R${plano.value.toString().slice(0, -2)},
                        {plano.value.toString().slice(-2)} / mês
                      </h5>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className={styles.nextPageContainer}>
                  <button onClick={prevPage} className={styles.prevBtn}>
                    Voltar
                  </button>
                  <button onClick={nextPage} className={styles.nextBtn}>
                    Próximo passo
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {page === 3 && (
          <div className={styles.descrptContainer}>
            <div className={styles.descrptSubContainer}>
              <h4 className={styles.pageTwoTitle}>Método de pagamento</h4>
              <div className={styles.areaRadio}>
                <form>
                  {plano.paymentPix && (
                    <div
                      className={styles.paymentPageRadio}
                      onClick={() => setPaymentType(1)}
                      style={
                        paymentType === 1
                          ? {
                              backgroundColor: "#E2D6E0",
                              border: "1px solid #E2D6E0",
                            }
                          : {}
                      }
                      id="pixDiv"
                    >
                      {paymentType === 1 ? (
                        <img src={pixIconCheckedImg} alt="Pix" />
                      ) : (
                        <img
                          src={pixIconImg}
                          alt="Pix"
                          onClick={() => setPaymentType(1)}
                        />
                      )}
                      <label
                        htmlFor="pix"
                        style={{
                          color: paymentType === 3 ? "#4C0040" : "#1A1A1A",
                        }}
                      >
                        PIX
                      </label>
                      <input
                        checked={paymentType === 1 ? true : false}
                        type="radio"
                        id="pix"
                        name="fav_language"
                        value="PIX"
                        readOnly={true}
                      />
                    </div>
                  )}
                  {plano.paymentCreditCard && (
                    <div
                      className={styles.paymentPageRadio}
                      onClick={creditCardPayment}
                      style={
                        paymentType === 2
                          ? {
                              backgroundColor: "#E2D6E0",
                              border: "1px solid #E2D6E0",
                            }
                          : {}
                      }
                      id="creditCardDiv"
                    >
                      {paymentType === 2 ? (
                        <img
                          htmlFor="credit_card"
                          src={cardIconCheckedImg}
                          alt="Cartão de credito"
                        />
                      ) : (
                        <img
                          htmlFor="credit_card"
                          src={cardIconImg}
                          alt="Cartão de credito"
                          onClick={() => setPaymentType(2)}
                        />
                      )}
                      <label
                        htmlFor="credit_card"
                        style={{
                          color: paymentType === 3 ? "#4C0040" : "#1A1A1A",
                        }}
                      >
                        Cartão de crédito
                      </label>
                      <input
                        checked={paymentType === 2 ? true : false}
                        type="radio"
                        id="credit_card"
                        name="fav_language"
                        value="CREDIT_CARD"
                        readOnly={true}
                      />
                    </div>
                  )}

                  {plano.paymentBoleto && (
                    <div
                      className={styles.paymentPageRadio}
                      onClick={() => setPaymentType(3)}
                      style={
                        paymentType === 3
                          ? {
                              backgroundColor: "#E2D6E0",
                              border: "1px solid #E2D6E0",
                            }
                          : {}
                      }
                      id="boletoDiv"
                    >
                      {paymentType === 3 ? (
                        <img src={boletoIconCheckedImg} alt="Boleto" />
                      ) : (
                        <img
                          id="boletoImg"
                          src={boletoIconImg}
                          alt="Boleto"
                          name="Boleto"
                          onClick={() => setPaymentType(3)}
                        />
                      )}
                      <label
                        htmlFor="boleto"
                        style={{
                          color: paymentType === 3 ? "#4C0040" : "#1A1A1A",
                        }}
                      >
                        Boleto Bancário
                      </label>
                      <input
                        checked={paymentType === 3 ? true : false}
                        type="radio"
                        id="boleto"
                        name="fav_language"
                        value="BOLETO"
                        readOnly={true}
                      />
                    </div>
                  )}
                </form>

                {paymentType === 2 && (
                  <div className={styles.cardData}>
                    <InputMask
                      className={styles.textInput}
                      {...register("cardNumber")}
                      onKeyDown={keyPressHandler}
                      onChange={handleChangeValues}
                      value={userRegister.cardNumber}
                      mask="9999 9999 9999 9999"
                      maskChar=""
                      name="cardNumber"
                      placeholder="Número do cartão*"
                    />
                    <input
                      className={styles.textInput}
                      {...register("cardName")}
                      onChange={handleChangeValues}
                      onKeyDown={keyPressHandler}
                      name="cardName"
                      placeholder="Nome impresso no cartão*"
                    ></input>
                    <div className={styles.twinInput}>
                      <InputMask
                        className={styles.textInput}
                        {...register("cardDate")}
                        mask="99/99"
                        maskChar=""
                        alwaysShowMask="false"
                        onChange={handleChangeValues}
                        onKeyDown={keyPressHandler}
                        value={userRegister.cardDate}
                        name="cardDate"
                        placeholder="Vencimento*"
                      />
                      <InputMask
                        className={styles.textInput}
                        {...register("cardCvv")}
                        mask="999"
                        maskChar=""
                        alwaysShowMask="false"
                        onChange={handleChangeValues}
                        onKeyDown={keyPressHandler}
                        value={userRegister.cardCvv}
                        name="cardCvv"
                        placeholder="CVV*"
                      />
                    </div>

                    {plano.credit_card_installments !== null &&
                      plano.credit_card_installments !== undefined && (
                        <div>
                          {plano.credit_card_installments !== 1 && (
                            <select
                              className={styles.selectPaymentInstallments}
                              {...register("installments")}
                              onChange={handleChangeValues}
                              placeholder=""
                            >
                              <option value="default">Selecione...*</option>
                              {instalList.map((installment) => {
                                return (
                                  <option
                                    key={installment.installment}
                                    value={installment.installment}
                                  >{`${
                                    installment.installment
                                  }x Vezes de ${numberFormat(
                                    installment.finalValue / 100
                                  )}`}</option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
            {width > 420 && (
              <div className={styles.payInfoContainer}>
                <div className={styles.payInfoSubContainer}>
                  <h4 className={styles.pageTwoTitle}>Resumo</h4>
                  <div className={styles.paymentInfo}>
                    <img src={plano.img} alt="time icone"></img>
                    <h3>{plano.title}</h3>
                    <h4>{plano.subtitle}</h4>
                    {plano.beneficios.map((text) => {
                      return (
                        <div className={styles.cardplan}>
                          <img
                            src="images/check-icon.png"
                            alt="ícone de checado"
                          ></img>
                          <h5>{text}</h5>
                        </div>
                      );
                    })}
                    <div className={styles.lineBreak}></div>

                    <div className={styles.paymentInfoItem}>
                      <h4>Valor total</h4>

                      <h5>
                        R${plano.value.toString().slice(0, -2)},
                        {plano.value.toString().slice(-2)} / mês
                      </h5>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className={styles.nextPageContainer}>
                  <button onClick={prevPage} className={styles.prevBtn}>
                    Voltar
                  </button>
                  <button onClick={nextPage} className={styles.nextBtn}>
                    Próximo passo
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {page === 4 && paymentResponse !== undefined && (
          <div>
            {paymentResponse.error === undefined ? (
              <div>
                {paymentType === 1 && (
                  <div className={styles.pixPaymentContainer}>
                    <h2>
                      Código <br /> PIX Copia e Cola
                    </h2>
                    <div className={styles.pixPaymentSubContainer}>
                      <div className={styles.pixCopyContainer}>
                        {width > 645 && (
                          <h3>{paymentResponse.pix.slice(0, 23)}...</h3>
                        )}
                        {width <= 645 && (
                          <h3>{paymentResponse.pix.slice(0, 35)}...</h3>
                        )}
                        <button
                          className={styles.copyPixBtn}
                          onClick={copyText(1)}
                        >
                          <img src={copyImg} alt="copiar" />
                          &nbsp;&nbsp;Copiar Codigo
                        </button>
                      </div>
                      <h3>
                        O codigo é valido até{" "}
                        <Moment format="DD/MM/YYYY HH:mm">
                          {paymentResponse.exp_date}
                        </Moment>
                      </h3>
                      <h3>Ou escaneie o QR Code abaixo</h3>
                      <QRCodeGen text={paymentResponse.pix}></QRCodeGen>
                      <div className={styles.pixValueContainer}>


                     
                      </div>
                      <h4>O número do seu pedido é</h4>
                      <h2>{paymentResponse.id}</h2>
                      <h5>
                        Enviaremos um e-mail para {userRegister.email} com os
                        detalhes do pedido.
                      </h5>
                      <h5>
                        Para informações adicionais sobre o produto ou entrega,
                        entre em contato com a equipe agsports.
                      </h5>
                    </div>
                    <div className={styles.footerPix}>
                      <div className={styles.footerPixLabel}>
                        <div className={styles.footerPixCircle}>
                          <label>1</label>
                        </div>
                        <h5>Copie o código de pagamento no botão acima</h5>
                      </div>
                      <div className={styles.footerPixLabel}>
                        <div className={styles.footerPixCircle}>
                          <label>2</label>
                        </div>
                        <h5>
                          Abra o app ou banco de sua preferência e cole o
                          código. Confira todas as informações e autorize o
                          pagamento.
                        </h5>
                      </div>
                      <div className={styles.footerPixLabel}>
                        <div className={styles.footerPixCircle}>
                          <label>3</label>
                        </div>
                        <h5>
                          Pronto! Em instantes vamos notificar o vendedor sobre
                          sua compra.
                        </h5>
                      </div>
                      <div className={styles.footerPixLabel}>
                        <h5>
                          Se o pagamento não for realizado, não se preocupe. O
                          pedido será cancelado automaticamente.
                        </h5>
                      </div>
                    </div>
                    <div className={styles.pixFooterLine}></div>
                  </div>
                )}
                {paymentType === 2 && (
                  <div className={styles.pixPaymentContainer}>
                    <div className={styles.creditCardTitle}>
                      <h2>Pagamento concluído</h2>
                    </div>
                    <div className={styles.pixPaymentSubContainer}>
                      <div className={styles.creditCardContainer}>
                        <h3>Valor da compra </h3>

                       
                      </div>
                      <div className={styles.installContainer}>
                        <h3>
                          Cartão finalizado em:{" "}
                          {paymentResponse.credit_card_last_numbers}
                        </h3>
                        <h3>Parcelamento: {userRegister.installments}x</h3>
                      </div>
                      <div className={styles.pixValueContainer}></div>
                      <h4>O número do seu pedido é</h4>
                      <h2>{paymentResponse.id}</h2>
                      <h5>
                        Enviaremos um e-mail para {userRegister.email} com os
                        detalhes do pedido.
                      </h5>
                      <h5>
                        Para informações adicionais sobre o produto ou entrega,
                        entre em contato com a equipe agsports.
                      </h5>
                    </div>
                  </div>
                )}
                {paymentType === 3 && (
                  <div className={styles.pixPaymentContainer}>
                    <h2>Copie o código do boleto bancário</h2>
                    <div className={styles.pixPaymentSubContainer}>
                      <div className={styles.pixCopyContainer}>
                        {width > 645 && (
                          <h3>{paymentResponse.boleto_code.slice(0, 23)}...</h3>
                        )}
                        {width <= 645 && (
                          <h3>{paymentResponse.boleto_code.slice(0, 35)}...</h3>
                        )}
                        <button
                          className={styles.copyPixBtn}
                          onClick={copyText(3)}
                        >
                          <img src={copyImg} alt="copiar" />
                          &nbsp;&nbsp;Copiar Codigo
                        </button>
                      </div>
                      <a
                        className={styles.linkBoleto}
                        href={paymentResponse.boleto_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Imprimir Boleto
                      </a>

                      <h3>
                        O boleto é válido até{" "}
                        <Moment format="DD/MM/YYYY HH:mm">
                          {paymentResponse.exp_date}
                        </Moment>
                      </h3>
                      <div className={styles.pixValueContainer}>


                      </div>
                      <h4>O número do seu pedido é</h4>
                      <h2>{paymentResponse.id}</h2>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <h1>{paymentResponse.user_message}</h1>
              </div>
            )}
          </div>
        )}
        {page !== 5 && (
          <div className={styles.paymentContainer}>
            <h5>Pague com</h5>
            <div className={styles.paymentType}>
              {plano.paymentCreditCard && (
                <div className={styles.payment}>
                  <img src={cardIconImg} alt="cartão de credito" />
                  <h5>Cartão de credito</h5>
                </div>
              )}
              {plano.paymentPix && (
                <div className={styles.payment}>
                  <img src={pixIconImg} alt="pix" />
                  <h5>PIX</h5>
                </div>
              )}
              {plano.paymentBoleto && (
                <div className={styles.payment}>
                  <img src={boletoIconImg} alt="boleto" />
                  <h5>Boleto Bancario</h5>
                </div>
              )}
            </div>
            <div className={styles.paymentFooter} style={width <= 420 ? { marginBottom: '2rem' } : {}}><h5>A Athletic Gear é somente a ferramenta para recebimento do valor do pedido. Informações sobre o produto ou o serviço adquiridos devem ser tratadas com a equipe <b>agsports</b></h5></div>
            {width > 420 && <Footer heightFooter={'15.813rem'} />}
            {width <= 420 && <Footer heightFooter={'15.813rem'} position={"static"} />}
            {width <= 420 && page !== 5 && <div className={styles.mobileEmpty}></div>}
          </div>
        )}
      </div>
      {paymentResponse !== undefined && (
        <div>
          {paymentDenied === 2 && (
            <div>
              <div
                className={styles.dimmerArea}
                style={{ height: { height } }}
              ></div>
              <div className={styles.popUpDenied}>
                <img src={paymentDeniedImg} alt="pagamento recusado" />
                <h2>
                  <br></br>
                  {paymentResponse.user_message}
                  <br></br>
                </h2>
                <button
                  onClick={() => {
                    window.location.reload();
                  }}
                  className={styles.deniBtn}
                >
                  Voltar ao Inicio
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {paymentDenied === 1 && (
        <div>
          <div
            className={styles.dimmerArea}
            style={{ height: { height } }}
          ></div>
          <div className={styles.popUpDenied}>
            <img src={paymentDeniedImg} alt="pagamento recusado" />
            <h3>Pagamento recusado</h3>
            <h4>Tente novamente com outra forma de pagamento</h4>
            <button
              onClick={() => {
                setPaymentDenied(false);
              }}
              className={styles.refuseBtn}
            >
              Entendi
            </button>
          </div>
        </div>
      )}
      {paymentDenied === 2 && (
        <div>
          <div
            className={styles.dimmerArea}
            style={{ height: { height } }}
          ></div>
          <div className={styles.popUpDenied}>
            <img src={paymentDeniedImg} alt="pagamento recusado" />
            <h2>
              <br></br>Ocorreu um erro, tente novamente mais tarde<br></br>
            </h2>
            <button
              onClick={() => {
                window.location.reload();
              }}
              className={styles.deniBtn}
            >
              Voltar ao Inicio
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Pagar;
