import Footer from "../elements/Footer";
import NavBar from "../elements/NavBar";
import styles from "../pages/RefuseInvite.module.css";

import emailBox from "../images/email-box.png";
import closeBtn from "../images/close-button.png";
import windowSize from "../elements/WindowsWatch";
import ButtonMain from "../elements/ButtonMain";

import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Loader from "../elements/Loader";

export default function RefuseInvite() {
  const { height } = windowSize();
  const location = useLocation();
  const [loader, setLoader] = useState();
  const [data, setData] = useState();
  const [errorFet, setErrorFet] = useState(false);
  const [refuseInv, setRefuseInv] = useState(0);
  const uuid = location.pathname.split("/")[2];

  const refuseInviteReturn = () => {
    fetch(
      `https://xwhfvujhvvctrgfgxwsz.supabase.co/functions/v1/cancel-invite?uuid=${uuid}`,
      { method: "DELETE" }
    )
    .then(response => response.json())
    .then(data =>{if(data.error === undefined){setRefuseInv(1)}else{setRefuseInv(2)}} )

  };



  const getData = () => {
    console.log(uuid)
    fetch(
      `https://xwhfvujhvvctrgfgxwsz.supabase.co/functions/v1/cancel-invite?uuid=${uuid}`
    )
      .then((response) => response.json())
      .then((data) => checkResponse(data))
      .catch((error) => checkError(error));
  };

  const checkResponse = (data) => {
    console.log(data.data);
    if (data.error !== undefined) {
      setErrorFet(true);
      setLoader(false);
    } else {
      setData(data);

      setLoader(false);
    }
  };




  const checkError = (error) => {
    if (error.message === "Failed to fetch") {
      setErrorFet(true);
      setLoader(false);
    } else if (error.message === "Not Found") {
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div>
      {loader && <Loader />}
      {errorFet ? (
        <div className={styles.notFoundDiv}>
          <NavBar showFaleconosco={false} />
          <div className={styles.notFoundArea}>
            <div className={styles.notFoundContainer}>
              <img src='images/link-notfound.svg' alt="link expirado"></img>
              <h4>Link inválido!</h4>
              <Link to="/" className={styles.nextBtn}>
                Voltar para o início
              </Link>
            </div>
          </div>
          <Footer heightFooter={"15.813rem"} />
        </div>
      ) : (
        <div>
          {data !== undefined && (
            <div className={styles.mainDiv} id="mainDiv">
              <span style={{ height: "4rem" }}></span>
              <NavBar showFaleconosco={false} />
              {refuseInv === 1 && (
                <div className={styles.sucessChange}>
                  <div
                    className={styles.dimmerArea}
                    style={{ height: { height } }}
                  ></div>
                  <div className={styles.popUpSucess}>
                    <Link className={styles.linkClose} to={"/"}>
                      <img
                        className={styles.sucessClose}
                        alt="fechar"
                        src={closeBtn}
                      ></img>
                    </Link>
                    <img src={emailBox} alt="salvo com sucesso" />
                    <h2>Convite recusado</h2>
                    <h5>Você não receberá mais notificações desse time.</h5>
                    <Link className={styles.deniBtn} to={"/"}>
                      Voltar para o Inicio
                    </Link>
                  </div>
                </div>
              )}
              
              {refuseInv === 2 &&(
                  <div className={styles.sucessChange}>
                    <div
                      className={styles.dimmerArea}
                      style={{ height: { height } }}
                    ></div>
                    <div className={styles.popUpSucess}>
                      <Link className={styles.linkClose} to={"/"}>
                        <img
                          className={styles.sucessClose}
                          alt="fechar"
                          src={closeBtn}
                        ></img>
                      </Link>
                      <img src={emailBox} alt="salvo com sucesso" />
                      <h2>Ocorre um erro!</h2>
                      <h5>Tente novamente mais tarde.</h5>
                      <Link className={styles.deniBtn} to={"/"}>
                        Voltar para o Inicio
                      </Link>
                    </div>
                  </div>
                )
              }

              <div className={styles.warningDiv}>
                <h4>
                  Gerenciando convite para o e-mail{" "}
                  <mark className={styles.highlightedText}>{data.email}</mark>
                </h4>
              </div>
              <br></br>
              <div className={styles.refuseDiv}>
                <div className={styles.refuseSubDiv}>
                    <img src={data.team.image} alt="icone do time" />
                  <div>
                    <h2>Recusar convite de {data.team.name}</h2>
                    <h3>
                      Caso você recuse, só poderá entrar no time mediante a
                      outro convite.
                    </h3>
                  </div>
                </div>

                <div className={styles.btnDiv}>
                  <Link to={'/'}>
                    <button className={styles.prevBtn}>Cancelar</button>
                  </Link>
                  <ButtonMain
                    onclickfunc={refuseInviteReturn}
                    txt={"Recusar convite"}
                  ></ButtonMain>
                </div>
              </div>
              <Footer heightFooter={"15.813rem"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
