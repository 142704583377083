import { useEffect, useState } from "react";
import infoIcon from "../images/info-icon-green.png";
import styles from "./ManageEmail.module.css";
import ButtonMain from "../elements/ButtonMain";
import Footer from "../elements/Footer";
import emailBox from "../images/email-box.png";
import windowSize from "../elements/WindowsWatch";
import closeBtn from "../images/close-button.png";
import paymentDeniedImg from '../images/payment-denied.png'
import linkNotFoundImg from "../images/link-notfound.png";
import { Link, useLocation } from "react-router-dom";
import NavBar from "../elements/NavBar";
import Loader from "../elements/Loader";

function ManageEmail() {
  const location = useLocation();
  const { height } = windowSize();
  const [changeSucess, setChangeSucess] = useState();
  const [changeFail, setChangeFail] = useState();
  const [loader, setLoader] = useState();
  const [data, setData] = useState();
  const [subData, setSubData] = useState();
  const [errorFet, setErrorFet] = useState(false);
  const uuid = location.pathname.split("/")[2];

  /*
  let listEmail = [
    { text: "Mensalidade gerada", value: false },
    { text: "Cobrança avulsa gerada", value: false },
    { text: "Lembrete de cobrança", value: false },
    { text: "Cobrança paga", value: false },
    { text: "Cobrança em atraso", value: false },
    { text: "Lembrete de cobrança", value: false },
    { text: "Atleta desativado pelo time", value: false },
  ];


  */
  const [listEmail, setListEmail] = useState();

  //let listEmail

  const clickSwitch = (e) => {
    //listEmail[e.target.name].value = e.target.checked;
    //setData()
    console.log(e.target.name);
    console.log(e.target.checked);
    setSubData((prevValue) => ({
      ...prevValue,
      [e.target.name]: { email: true, push: e.target.checked },
    }));

    console.log(subData);
  };

  const postData = () => {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    let raw = JSON.stringify({
      data: subData,
    });
    console.log(raw);

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `https://xwhfvujhvvctrgfgxwsz.supabase.co/functions/v1/notification-settings?uuid=${uuid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.user_message === "Salvo com sucesso") {
          setChangeSucess(!changeSucess);
        } else {
          setChangeFail(true);
        }
      });

    //;
    //console.log(listEmail);
  };

  const getData = () => {
    fetch(
      `https://xwhfvujhvvctrgfgxwsz.supabase.co/functions/v1/notification-settings?uuid=${uuid}`
    )
      .then((response) => response.json())
      .then((data) => checkResponse(data))
      .catch((error) => checkError(error));
  };

  const checkResponse = (data) => {
    console.log(data.data);
    if (data.error !== undefined) {
      setErrorFet(true);
      setLoader(false);
    } else {
      setData(data);

      setLoader(false);
    }
  };

  const checkError = (error) => {
    console.log("aaaa");
    if (error.message === "Failed to fetch") {
      setErrorFet(true);
      setLoader(false);
    } else if (error.message === "Not Found") {
      setErrorFet(true);
      setLoader(false);
    }
  };

  useEffect(() => {
    getData();
            // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data !== undefined) {
      var result = Object.keys(data.data).map((key) => [key, data.data[key]]);
      console.log(result);
      setListEmail(result);
      setSubData(data.data);
    }
  }, [data]);

  return (
    <div>
      <button
        onClick={() => {
          console.log(data);
        }}
      ></button>

      {loader && <Loader />}
      {errorFet ? (
        <div className={styles.notFoundDiv}>
          <NavBar showFaleconosco={false} />
          <div className={styles.notFoundArea}>
            <div className={styles.notFoundContainer}>
              <img src={linkNotFoundImg} alt="link expirado"></img>
              <h4>Link inválido!</h4>
              <Link to="/" className={styles.nextBtn}>
                Voltar para o início
              </Link>
            </div>
          </div>
          <Footer heightFooter={"15.813rem"} />
        </div>
      ) : (
        <div>
          {listEmail !== undefined && (
            <div className={styles.mainDiv} id="mainDiv">
              <span style={{ height: "4rem" }}></span>

              {changeSucess && (
                <div className={styles.sucessChange}>
                  <div
                    className={styles.dimmerArea}
                    style={{ height: { height } }}
                  ></div>
                  <div className={styles.popUpSucess}>
                    <img
                      className={styles.sucessClose}
                      alt="botão"
                      onClick={() => {
                        setChangeSucess(false);
                      }}
                      src={closeBtn}
                    ></img>
                    <img src={emailBox} alt="salvo com sucesso" />
                    <h2>
                      <br></br>
                      Configurações salvas com sucesso
                      <br></br>
                    </h2>
                    <Link className={styles.deniBtn} to={"/"}>
                      Voltar para o Inicio
                    </Link>
                  </div>
                </div>
              )}
              {changeFail && (
                <div>
                  <div
                    className={styles.dimmerArea}
                 
                  ></div>
                  <div className={styles.popUpDenied}>
                    <img src={paymentDeniedImg} alt="pagamento recusado" />
                    <h2>
                      <br></br>
                      Ocorreu um erro! tente novamento mais tarde.
                      <br></br>
                    </h2>
                  <Link to={'/'}>
                  <button
                      className={styles.deniBtn}
                    >
                      Voltar ao Inicio
                    </button></Link>
                  </div>
                </div>
              )}
              <div className={styles.warningDiv}>
                <h4>
                  Configurando e-mails para a conta{" "}
                  <mark className={styles.highlightedText}>test@gmail.com</mark>
                </h4>
              </div>
              <div className={styles.mainSubDiv} style={{ marginTop: "0rem" }}>
                <h2>Configurações de e-mails</h2>
                <h4>Escolha quais notificações deseja receber por e-mail:</h4>
              </div>
              <div className={styles.checkArea}>
                <div className={styles.sucessInfoDiv}>
                  <img src={infoIcon} alt="informação"></img>
                  <h4>
                    {" "}
                    Desativar e-mails pode levar a esquecer avisos importantes.
                    Mantenha ativo para não perder lembretes essenciais.{" "}
                  </h4>
                </div>
                <div>
                  {listEmail.map((email, index) => {
                    return (
                      <div key={index} className={styles.switchListDiv}>
                        <p>{email[0]}</p>
                        <label className={styles.switchDiv}>
                          <input
                            type="checkbox"
                            name={email[0]}
                            defaultChecked={email[1].push}
                            className={styles.inputSwitch}
                            onClick={clickSwitch}
                          ></input>
                          <span className={styles.slider}></span>
                        </label>
                      </div>
                    );
                  })}
                </div>
                <div className={styles.btnExcluir}>
                  <ButtonMain
                    onclickfunc={postData}
                    txt={"Salvar"}
                  ></ButtonMain>
                </div>
              </div>
              <div
                className={styles.footerFiller}
                style={{ height: "20rem" }}
              ></div>
              <Footer heightFooter={"15.813rem"} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ManageEmail;
